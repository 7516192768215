import { classList, documentReady, qs, qsa } from '@js/utils/toolbox';
import '@module/LinkBox/LinkBox.scss';

documentReady(() => {
	qsa('[data-tab-component]').forEach((tabComponent) => {
		tabComponent.addEventListener('click', (event) => {
			if (event.target.hasAttribute('data-tab-component-button-index')) {
				// Hide old tab
				const oldTab = qs('[data-tab-component-item-index].tab-active', tabComponent);
				classList(oldTab).remove('tab-active');

				// Set new button active
				const oldButton = qs('[data-tab-component-button-index].abTabNav__btn--active', tabComponent);
				classList(oldButton).remove('abTabNav__btn--active');
				classList(event.target).add('abTabNav__btn--active');

				// Show new tab
				const index = event.target.getAttribute('data-tab-component-button-index');
				const newTab = qs('[data-tab-component-item-index="' + index + '"]', tabComponent);
				classList(newTab).add('tab-active');
			}
		});
	});
});
